import loadable from '@loadable/component';
import { college_votes, department_votes, faculty_data, raw, semantic_scholar } from 'volto-facultycv/reducers';
import content from '@plone/volto/reducers/content/content';
import search from '@plone/volto/reducers/search/search';
import FacultyCVBlockTemplate from 'volto-facultycv/components/Blocks/FacultyCV/FacultyCVBlockTemplate';
// Use named import if FacultyCVSchema is not a default export
import { FacultyCVSchema } from 'volto-facultycv/components/Blocks/FacultyCV/FacultyCVSchema';


const LazyProfileStaff = loadable(() => import('volto-facultycv/components/Views/ProfileStaff'));
const LazyOfficeHoursWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/OfficeHoursWidget'));
const LazyEducationWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/EducationWidget'));
const LazyActivitiesWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ActivitiesWidget'));
const LazyArticlesWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ArticlesWidget'));


const LazyBooksWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/BooksWidget'));
const LazyChaptersWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ChaptersWidget'));
const LazyCoursesTaughtWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/CoursesTaughtWidget'));
const LazyDevelopedWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/DevelopedWidget'));
const LazyEmploymentRecordWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/EmploymentRecordWidget'));
const LazyExperienceFTWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ExperienceFTWidget'));
const LazyExperienceNAWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ExperienceNAWidget'));
const LazyExperiencePTWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ExperiencePTWidget'));
const LazyGrantsWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/GrantsWidget'));
const LazyMonographsWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/MonographsWidget'));
const LazyNonRefereedProceedingsWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/NonRefereedProceedingsWidget'));
const LazyOfficeHeldWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/OfficeHeldWidget'));
const LazyPatentsWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/PatentsWidget'));
const LazyPresentedWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/PresentedWidget'));
const LazyProfessionalWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ProfessionalWidget'));
const LazyRefereedProceedingsWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/RefereedProceedingsWidget'));
const LazyReviewsWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ReviewsWidget'));
const LazyServiceCollegeWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ServiceCollegeWidget'));
const LazyServiceDepartmentWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ServiceDepartmentWidget'));
const LazyServiceGraduateCenterWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ServiceGraduateCenterWidget'));
const LazyServiceSchoolWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ServiceSchoolWidget'));
const LazyServiceUniversityWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/ServiceUniversityWidget'));
const LazyDurationWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/DurationWidget'));
const LazyDatesWidget = loadable(() => import('volto-facultycv/components/DataGridFieldWidget/DatesWidget'));
const LazyDataGridFieldWidget = loadable(() => import('volto-facultycv/Widgets/DataGridFieldWidget'));
const LazyPBAction = loadable(() => import('volto-facultycv/components/Views/PBAction'));
const LazyProfileDefault = loadable(() => import('volto-facultycv/components/Views/ProfileDefault'));
const LazyPBDocumentsRepository = loadable(() => import('volto-facultycv/components/Views/PBDocumentsRepository'));
const LazyPBActionTypeView = loadable(() => import('volto-facultycv/components/Views/PBActionTypeView'));
const LazyCurriculumVitae = loadable(() => import('volto-facultycv/components/Views/CurriculumVitae'));
const LazyEnhancedCurriculumVitae = loadable(() => import('volto-facultycv/components/Views/EnhancedCurriculumVitae'));

const applyConfig = (config) => {
  config.addonRoutes.push({
    path: '/**/cv', component: LazyCurriculumVitae,
  });
  config.addonRoutes.push({
    path: '/**/enhanced-c-v', component: LazyEnhancedCurriculumVitae,
  });
  config.addonRoutes.push({
    path: '/**/pbactiontypeview', component: LazyPBActionTypeView,
  });

  config.settings.nonContentRoutes.push('/cv');
  config.settings.nonContentRoutes.push('/enhanced-c-v');
  config.settings.nonContentRoutes.push('/pbactiontypeview');
  config.settings.externalRoutes = [
    { match: '/cv' },
    { match: '/enhanced-c-v' },
    { match: '/pbactiontypeview' },
  ];

  config.addonReducers.faculty_data = faculty_data;
  config.addonReducers.college_votes = college_votes;
  config.addonReducers.department_votes = department_votes;
  config.addonReducers.raw = raw;
  config.addonReducers.semantic_scholar = semantic_scholar;
  config.addonReducers.search = search;
  config.addonReducers.content = content;

  config.blocks.blocksConfig.listing.variations = [
    {
      id: 'college',
      title: 'Directory',
      template: FacultyCVBlockTemplate,
      schemaEnhancer: FacultyCVSchema,
    },
    ...config.blocks.blocksConfig.listing.variations,
  ];

  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      faculty2: LazyProfileDefault,
      staff2: LazyProfileStaff,
      poe: LazyProfileStaff,
      pbaction2: LazyPBAction,
      symposium_talk: LazyPBAction,
      pbactionportfolioa: LazyPBAction,
      pbactionportfoliob: LazyPBAction,
      pbactionportfolioc: LazyPBAction,
      pbactionclt2: LazyPBAction,
      pbactionleave2: LazyPBAction,
      pbactionscholar2: LazyPBAction,
      'pb-reappointment-7': LazyPBAction,
      'pb-promotion-to-associate': LazyPBAction,
      'pb-reappointment-1': LazyPBAction,
      'pb-reappointment-2': LazyPBAction,
      'pb-reappointment-4': LazyPBAction,
      'pb-reappointment-5': LazyPBAction,
      'pb-reappointment-6': LazyPBAction,
      'pb-reappointment-3': LazyPBAction,
      'pb-reappointment-5-lecturer': LazyPBAction,
      'pb-clt': LazyPBAction,
      'pb-leave-request': LazyPBAction,
      'pb-promotion-full': LazyPBAction,
      'pb-scholar-incentive-award': LazyPBAction,
    },
    layoutViews: {
      ...config.views.layoutViews,
      'p-b-documents-repository': LazyPBDocumentsRepository,
    },
  };

  config.widgets.id.officeHours = LazyOfficeHoursWidget;
  config.widgets.id.education = LazyEducationWidget;
  config.widgets.id.activities = LazyActivitiesWidget;
  config.widgets.id.articles = LazyArticlesWidget;
  config.widgets.id.books = LazyBooksWidget;
  config.widgets.id.chapters = LazyChaptersWidget;
  config.widgets.id.coursesTaught = LazyCoursesTaughtWidget;
  config.widgets.id.developed = LazyDevelopedWidget;
  config.widgets.id.employmentRecord = LazyEmploymentRecordWidget;
  config.widgets.id.experienceFT = LazyExperienceFTWidget;
  config.widgets.id.experienceNA = LazyExperienceNAWidget;
  config.widgets.id.experiencePT = LazyExperiencePTWidget;
  config.widgets.id.grants = LazyGrantsWidget;
  config.widgets.id.monographs = LazyMonographsWidget;
  config.widgets.id.nonRefereedProceedings = LazyNonRefereedProceedingsWidget;
  config.widgets.id.officeHeld = LazyOfficeHeldWidget;
  config.widgets.id.patents = LazyPatentsWidget;
  config.widgets.id.presented = LazyPresentedWidget;
  config.widgets.id.professional = LazyProfessionalWidget;
  config.widgets.id.refereedProceedings = LazyRefereedProceedingsWidget;
  config.widgets.id.reviews = LazyReviewsWidget;
  config.widgets.id.serviceCollege = LazyServiceCollegeWidget;
  config.widgets.id.serviceDepartment = LazyServiceDepartmentWidget;
  config.widgets.id.serviceGraduateCenter = LazyServiceGraduateCenterWidget;
  config.widgets.id.serviceSchool = LazyServiceSchoolWidget;
  config.widgets.id.serviceUniversity = LazyServiceUniversityWidget;
  config.widgets.id.duration = LazyDurationWidget;
  config.widgets.id.dates = LazyDatesWidget;
  config.widgets.widget.datagridfield = LazyDataGridFieldWidget;

  return config;
};

export default applyConfig;
