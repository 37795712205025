export const links = [
  {
    name: 'Future Student',
    link: '/admissions',
    styles: 'cd-nav-icons',
    sublinks: [
      {
        name: 'Freshman Admissions',
        link: '/admissions/freshman',
        styles: 'item-1',
        description: 'I have never attended college',
      },
      {
        name: 'Transfer Admissions',
        link: '/admissions/transfer',
        styles: 'item-2',
        description: 'I studied at other post-secondary institutions',
      },
      {
        name: 'Graduate Admissions',
        link: '/admissions/graduate',
        styles: 'item-3',
        description: 'I graduated from a post-secondary institution',
      },
      {
        name: 'International Admissions',
        link: '/admissions/international',
        styles: 'item-4',
        description: 'I\'m not a US Resident / Citizen',
      },
      {
        name: 'Veterans Admission',
        link: '/veterans-affairs',
        styles: 'item-5',
        description: 'I served in the US military',
      },
      {
        name: 'Other Applicants',
        link: '/admissions/others',
        styles: 'item-6',
        description: 'Non-degree, Second Degree, Adult Learner, Senior Citizens',
      },
      {
        name: 'Admissions Team/Office Hours',
        link: '/admissions/team',
        styles: 'item-7',
        description: 'Meet the York College Admission Team, Virtual and In-person Walk-In Hours',
      },
      {
        name: 'Campus Tour',
        link: '/admissions/campus-tours',
        styles: 'item-8',
        description: 'Take a virtual Tour of our campus',
      },
      {
        name: 'Financial Aid',
        link: '/financial-aid',
        styles: 'item-9',
        description: 'I need help paying for college',
      },
    ],
  },
  {
    name: 'Current Student',
    link: '/current-student',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Online Services',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'menustrong', name: 'Brightspace / Blackboard' },
          { link: '/news/2018/the-york-college-online-bookstore', name: 'Bookstore' },
          {
            link: 'https://www2.cuny.edu/about/administration/offices/registrar/resources/ePermit/',
            name: 'CUNY ePermit',
          },
          { link: 'https://home.cunyfirst.cuny.edu/', styles: 'menustrong', name: 'CUNYfirst' },
          { link: 'https://degreeworks.cuny.edu/Dashboard_yk', name: 'DegreeWorks' },
          { link: 'https://dropbox.cuny.edu/', styles: 'menustrong', name: 'Dropbox' },
          { link: 'https://www.outlook.com/owa/yorkmail.cuny.edu', styles: 'menustrong', name: 'e-mail / Office 365' },
          { link: 'https://www.york.cuny.edu/it/service-delivery-unit/y-connect', name: 'IT Self Service' },
          { link: '/academics/navigate', name: 'Navigate' },
          { link: 'https://parking.york.cuny.edu/', name: 'Parking Permits and Violations' },
          { link: '/myid', name: 'MyID / Password Management' },
          { link: '/registrar/schedule-builder', name: 'Schedule Builder' },
          { link: '/tutor', styles: 'menustrong', name: 'Tutor.com' },
          { link: '/it/network-access/vpn', styles: 'menustrong', name: 'VPN Access' },
          { link: 'https://cuny.zoom.us', name: 'Zoom' },

        ],
      },
      {
        name: 'Academics',
        link: '/academics',
        styles: 'has-children',
        sublink: [
          { link: '/advisement', name: 'Academic Advisement Center' },
          { link: '/registrar/calendar', styles: 'menustrong', name: 'Academic Calendar' },
          { link: '/academics/honors', name: 'Academic Honors' },
          {
            link: '/academics/academic-affairs',
            name: 'Academic Affairs',
            styles: 'has-children',
            sublink: [
              { link: '/college-now', name: 'College Now' },
              { link: '/cuny-explorers', name: 'CUNY Explorers' },
              { link: '/honors', name: 'Honors Program' },
              { link: '/undergraduate-research', name: 'Undergraduate Research' },
              { link: '/university-skills-immersion-program', name: 'University Skills and Immersion Program (USIP)' },
              { link: '/academics/york-stem-academy', name: 'York STEM Academy' },
            ],
          },
          { link: '/ace', name: 'Accelerate, Complete, Engage (ACE)' },
          {
            link: 'https://york-graduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Graduate',
          },
          {
            link: 'https://york-undergraduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Undergraduate',
          },
          { link: '/collaborative-learning-center', name: 'Collaborative Learning Center' },
          { link: '/commencement', name: 'Commencement' },
          { link: '/registrar/course-schedule', name: 'Course Schedule' },

          { link: '/registrar', styles: 'menustrong', name: 'Registrar' },
          {
            link: '/arts-and-sciences',
            name: 'School of Arts & Sciences',
            styles: 'has-children',
            sublink: [
              { link: '/behavioral-sciences', name: 'Behavioral Sciences' },
              { link: '/biology', name: 'Biology' },
              { link: '/chemistry', name: 'Chemistry' },
              { link: '/earth-and-physical-sciences', name: 'Earth and Physical Sciences' },
              { link: '/english', name: 'English' },
              { link: '/history-and-philosophy', name: 'History, Philosophy, and Anthropology' },
              {
                link: '/mathematics-and-computer-science',
                name: 'Mathematics and Computer Science',
              },
              { link: '/performing-and-fine-arts', name: 'Performing and Fine Arts' },
              {
                link: '/foreign-languages-esl-humanities',
                name: 'World Languages, Literatures, and Humanities',
              },
            ],
          },
          {
            link: '/business-and-information-systems',
            name: 'School of Business & Information Systems',
            styles: 'has-children',
            sublink: [
              { link: '/accounting-and-finance', name: 'Accounting and Finance' },
              { link: '/business-economics', name: 'Business and Economics' },
              { link: '/aviation-institute', name: 'CUNY Aviation Institute' },
            ],
          },
          {
            link: '/health-sciences-and-professional-programs',
            name: 'School of Health Sciences & Professional Programs',
            styles: 'has-children',
            sublink: [
              { link: '/health-human-performance', name: 'Health & Human Performance' },
              { link: '/health-professions', name: 'Health Professions' },
              { link: '/nursing', name: 'Nursing' },
              { link: '/occupational-therapy', name: 'Occupational Therapy' },
              { link: '/social-work', name: 'Social Work' },
              { link: '/teacher-education', name: 'Teacher Education' },
            ],
          },
          { link: '/wac', name: 'Writing Across the Curriculum' },

        ],
      },
      {
        name: 'Student Affairs',
        link: '/student-development',
        styles: 'has-children',
        sublink: [
          { link: '/student-development', name: 'Student Affairs and Enrollment Management' },
          { link: 'http://www.yorkathletics.com/', name: 'Athletics' },
          { link: '/career-services', name: 'Career Services' },

          { link: '/child-and-family-center', name: 'Child and Family Center' },

          { link: '/student-development/fye', name: 'First-Year Experience (FYE)' },

          { link: '/mens-center', name: 'Male Initiative Program' },
          { link: '/student-development/mentoring', name: 'Mentoring' },
          {
            link: '/enrollment-mngmt-office',
            name: 'Office of Enrollment Management',
            styles: 'has-children',
            sublink: [
              { link: '/admissions', name: 'Admissions' },
              { link: '/financial-aid', name: 'Financial Aid' },
              { link: '/veterans-affairs', name: 'Veteran\'s Affairs' },
              { link: '/welcome-center', name: 'Welcome Center' },
            ],
          },
          { link: '/ombudsperson', name: 'Office of Ombudsperson' },
          { link: '/seek', name: 'Percy E. Sutton SEEK program' },
          {
            link: '/student-development/student-activities',
            name: 'Student Activities',
            styles: 'has-children',
            sublink: [
              { link: '/student-development/jumpstart', name: 'JumpStart' },
              {
                link: '/student-development/student-activities/student-clubs-and-organization/the-national-society-of-leadership-and-success',
                name: 'National Society of Leadership and Success',
              },
              {
                link: '/student-development/student-activities/student-government',
                name: 'Student Government Association',
              },
              { link: '/ycradio', name: 'YCRadio' },
            ],
          },
          {
            link: '/cares',
            name: 'Wellness and Resources',
            styles: 'has-children',
            sublink: [
              { link: '/counseling-center', name: 'Counseling Center' },
              { link: '/csd', name: 'Center for Students with Disabilities' },
              { link: '/health', name: 'Health Services' },
              { link: '/womens-center', name: 'Women\'s Center for Gender Justice' },
            ],
          },

        ],
      },
      {
        name: 'Student Resources',
        link: '/current-student',
        styles: 'has-children',
        sublink: [
          { link: '/student-development/bit', name: 'Behavioral Intervention Team (BIT)' },
          { link: '/events', name: 'Calendar' },
          {
            link: '/ctlet',
            name: 'Center for Teaching, Learning and Educational Technologies (CTLET)',
          },
          { link: '/about/consumer-information', name: 'Consumer Information' },
          { link: '/directory', name: 'Directory' },
          { link: '/financial-aid', styles: 'menustrong', name: 'Financial Aid Office' },
          { link: '/library', name: 'Library' },
          { link: '/ctlet/lynda', name: 'LinkedIn Learning' },
          { link: '/bursar', styles: 'menustrong', name: 'Office of the Bursar' },
          { link: '/public-safety', name: 'Public Safety' },
          { link: '/senate', styles: 'menustrong', name: 'Senate' },
          { link: '/scholarship', styles: 'menustrong', name: 'Scholarships Center' },
          { link: '/study-abroad', name: 'Study Abroad' },
          { link: '/pac', name: 'The Milton G. Bassin Performing Arts Center' },
          { link: '/welcome-center', styles: 'menustrong', name: 'Welcome Center' },
        ],
      },
    ],
  },
  {
    name: 'Faculty / Staff',
    link: '/faculty-staff',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Online Services',
        styles: 'has-children',
        sublink: [
          {
            link: '/human-resources/current-employee',
            styles: 'menustrong',
            name: 'AEMS | HR/PR Assist',
          },
          {
            link: '/administrative/office-of-facilities-and-planning/bg/archibus',
            name: 'B&G Work Order',
          },
          { link: '/news/2018/the-york-college-online-bookstore', name: 'Bookstore' },
          { link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'menustrong', name: 'Brightspace / Blackboard' },
          { link: 'https://solutions.sciquest.com/apps/Router/SAMLAuth/CUNY', styles: '_blank', name: 'CUNYBuy' },
          { link: 'https://home.cunyfirst.cuny.edu/', styles: '_blank', name: 'CUNYFirst' },
          { link: 'https://degreeworks.cuny.edu/', name: 'DegreeWorks' },
          { link: 'https://dropbox.cuny.edu/', name: 'Dropbox' },
          {
            link: 'https://login.microsoftonline.com/?whr=login.cuny.edu',
            styles: 'menustrong',
            name: 'e-mail / Office 365',
          },
          { link: '/it/service-delivery-unit/y-connect', name: 'IT Self Service' },
          { link: '/academics/navigate', name: 'Navigate' },
          { link: 'https://parking.york.cuny.edu', name: 'Parking Permits and Violations' },
          { link: '/it/service-delivery-unit/qualtrics', name: 'Qualtrics' },
          { link: '/it/network-access/vpn', styles: 'menustrong', name: 'VPN Access' },
          { link: '/login?return_url=/dashboard', name: 'York Website/CMS' },
          { link: 'https://cuny.zoom.us', styles: 'menustrong', name: 'Zoom' },

        ],
      },
      {
        name: 'Academics',
        link: '/academics/academic-affairs',
        styles: 'has-children',
        sublink: [
          { link: '/academic-affairs', name: 'Academic Affairs' },
          { link: '/advisement', name: 'Academic Advisement Center' },
          { link: '/registrar/calendar', styles: 'menustrong', name: 'Academic Calendar' },
          {
            link: '/academics/academic-affairs/newsletter',
            name: 'Academic Affairs Newsletters',
          },
          {
            link: 'https://york-graduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Graduate',
          },

          {
            link: 'https://york-undergraduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Undergraduate',
          },
          {
            link: '/academics/academic-affairs/faculty-development',
            name: 'Faculty Development',
          },
          { link: '/academics/academic-affairs/faculty-resources', name: 'Faculty Resources' },
          { link: '/academics/academic-affairs/pathways', name: 'General Education' },
          { link: '/registrar', name: 'Registrar' },
          { link: '/orsp', name: 'Research and Sponsored Program' },
          {
            link: '/arts-and-sciences',
            name: 'School of Arts & Sciences',
            styles: 'has-children',
            sublink: [
              { link: '/behavioral-sciences', name: 'Behavioral Sciences' },
              { link: '/biology', name: 'Biology' },
              { link: '/chemistry', name: 'Chemistry' },
              { link: '/earth-and-physical-sciences', name: 'Earth and Physical Sciences' },
              { link: '/english', name: 'English' },
              { link: '/history-and-philosophy', name: 'History, Philosophy, and Anthropology' },
              {
                link: '/mathematics-and-computer-science',
                name: 'Mathematics and Computer Science',
              },
              { link: '/performing-and-fine-arts', name: 'Performing and Fine Arts' },
              {
                link: '/foreign-languages-esl-humanities',
                name: 'World Languages, Literatures, and Humanities',
              },
            ],
          },
          {
            link: '/business-and-information-systems',
            name: 'School of Business & Information Systems',
            styles: 'has-children',
            sublink: [
              { link: '/accounting-and-finance', name: 'Accounting and Finance' },
              { link: '/business-economics', name: 'Business and Economics' },
              { link: '/aviation-institute', name: 'CUNY Aviation Institute' },
            ],
          },
          {
            link: '/health-sciences-and-professional-programs',
            name: 'School of Health Sciences & Professional Programs',
            styles: 'has-children',
            sublink: [
              { link: '/health-human-performance', name: 'Health & Human Performance' },
              { link: '/health-professions', name: 'Health Professions' },
              { link: '/nursing', name: 'Nursing' },
              { link: '/occupational-therapy', name: 'Occupational Therapy' },
              { link: '/social-work', name: 'Social Work' },
              { link: '/teacher-education', name: 'Teacher Education' },
            ],
          },
          { link: '/undergraduate-research', name: 'Undergraduate Research' },
          { link: '/wac', name: 'Writing Across the Curriculum' },
        ],
      },
      {
        name: 'Administration',
        link: '/administrative',
        styles: 'has-children',
        sublink: [
          {
            link: '/president',
            name: 'Office of the President',
            styles: 'has-children',
            sublink: [
              {
                link: '/government-relations-and-strategic-initiatives',
                name: 'Government Relations and Strategic Initiatives',
              },
              {
                link: '/institutional-effectiveness',
                name: 'Institutional Effectiveness and Strategic Planning',
              },
              { link: '/diversity-and-compliance', name: 'Diversity and Compliance' },
              { link: '/legal', name: 'Legal Affairs and Labor Relations' }],
          },
          {
            link: '/administrative/office-of-facilities-and-planning',
            name: 'Facilities and Planning',
            styles: 'has-children',
            sublink: [
              {
                link: '/administrative/office-of-facilities-and-planning/bg',
                name: 'Building and Grounds',
              },
              {
                link: '/administrative/office-of-facilities-and-planning/environmental',
                name: 'Environment Health and Safety',
              },
              { link: '/pac', name: 'Performing Arts Center' },
              {
                link: '/administrative/office-of-facilities-and-planning/special-events-office',
                name: 'Special Events Office',
              }],
          },
          {
            link: '/administrative/business-office',
            name: 'Financial Planning and Budget',
            styles: 'has-children',
            sublink: [
              {
                link: '/administrative/financial-planning-and-budget/budget-office',
                name: 'Budget Office',
              },
              { link: '/bursar', name: 'Bursar Office' },
              {
                link: '/administrative/financial-planning-and-budget/business-office',
                name: 'Business Office',
              },
              {
                link: '/administrative/financial-planning-and-budget/purchasing',
                name: 'Purchasing',
              },
            ],
          },
          {
            link: '/president/government-and-community-relations',
            name: 'Government Relations and Strategic Initiatives',
            styles: 'has-children',
            sublink: [
              {
                link: '/conted',
                name: 'Continuing and Professional Education Center',
              },
              {
                link: '/learning-center',
                name: 'York College Learning Center',
              },
              {
                link: '/sbdc',
                name: 'Small Business Development Center',
              },
              {
                link: '/start-up-ny',
                name: 'START-UP-NY',
              }],
          },
          {
            link: '/human-resources',
            styles: 'menustrong',
            name: 'Human Resources',
          },
          {
            link: '/it',
            name: 'Information Technology',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/it/network-access', name: 'Infrastructure' },
              { link: 'https://www.york.cuny.edu/it/service-delivery-unit', name: 'Service Delivery' },
              { link: 'https://www.york.cuny.edu/webteam', name: 'Web and Business Intelligence' },
            ],
          },
          {
            link: '/ia',
            name: 'Office of Institutional Advancement',
            styles: 'has-children',
            sublink: [
              { link: '/ia/dev-alum', name: 'Alumni Affairs' },
              { link: '/ia/annual-fund', name: 'Annual Fund' },
              { link: '/ia/donor-relations', name: 'Donor Relations' },
              { link: '/ia/giving', name: 'Give to York' },
              { link: '/ia/support-scholarships', name: 'Support Scholarships' },
              { link: '/ia/foundation-board', name: 'York College Foundation' },
            ],
          },
          {
            link: '/institutional-effectiveness',
            name: 'Office of Institutional Effectiveness',
          },
          { link: '/public-safety', styles: 'menustrong', name: 'Public Safety' },
        ],
      },
      {
        name: 'Resources',
        styles: 'has-children',
        sublink: [
          { link: '/student-development/bit', name: 'Behavioral Intervention Team (BIT)' },
          { link: '/events', name: 'Calendar' },
          {
            link: '/cares',
            name: 'Wellness and Resources',
            styles: 'has-children',
            sublink: [
              { link: '/counseling-center', name: 'Counseling Center' },
              { link: '/csd', name: 'Center for Students with Disabilities' },
              { link: '/health', name: 'Health Services' },
              { link: '/womens-center', name: 'Women\'s Center for Gender Justice' },
            ],
          },
          {
            link: '/ctlet',
            name: 'Center for Teaching, Learning and Educational    Technologies (CTLET)',
          },
          {
            link: 'https://bbhosted.cuny.edu/webapps/blackboard/execute/announcement?method=search&context=course_entry&course_id=_2323641_1&handle=announcements_entry&mode=view',
            styles: 'menustrong',
            name: 'Cybersecurity Awareness Training',
          },
          { link: '/directory', styles: 'menustrong', name: 'Directory' },
          { link: '/library', styles: 'menustrong', name: 'Library' },
          { link: '/ctlet/lynda', name: 'LinkedIn Learning' },
          {
            link: '/marketing',
            name: 'Marketing and Communications',
          },
          { link: '/bursar', name: 'Office of the Bursar' },
          { link: '/administrative/printing-services', name: 'Printing Services' },
          { link: '/senate', styles: 'menustrong', name: 'Senate' },
          { link: '/pac', name: 'The Milton G. Bassin Performing Arts Center' },
          { link: '/yams', name: 'York Assessment Management System (YAMS)' },
        ],
      },
    ],
  },
  {
    name: 'Alumni / Friends',
    link: '/alumni',
    styles: 'cd-nav-gallery',
    sublinks: [
      {
        name: 'Institutional Advancement',
        link: '/ia',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/friends-development.jpg',
      },
      {
        name: 'YC Foundation, Inc.',
        link: '/ia/foundation-board',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/friends-ycf.png',
      },
      {
        name: 'Alumni Association, Inc.',
        link: '/alumni/association',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/friends-alumni.jpg',
      },
      {
        name: 'Alumni Relations',
        link: '/ia/devalum',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/alumni-resources.jpg',
      },
    ],
  },
];
