import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { flattenToAppURL, isInternalURL, withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

export const View = ({ className, data, detached, properties, style }) => {
  const href = data?.href?.[0]?.['@id'] || '';

  // Load the Image component dynamically
  const Image = config.getComponent({ name: 'Image' }).component;

  // Helper function to get srcSet based on the image scales
  const getSrcSet = (baseUrl) => {
    if (!baseUrl) return '';
    return `
      ${baseUrl}/@@images/image/mini 200w,
      ${baseUrl}/@@images/image/preview 400w,
      ${baseUrl}/@@images/image/teaser 600w,
      ${baseUrl}/@@images/image/large 800w,
      ${baseUrl}/@@images/image/larger 1000w,
      ${baseUrl}/@@images/image/great 1200w,
      ${baseUrl}/@@images/image/huge 1600w
    `;
  };

  // Helper function to get sizes attribute based on the container breakpoints
  const getSizes = () => {
    return `
      (max-width: 767px) 400px,
      (min-width: 768px) and (max-width: 991px) 600px,
      (min-width: 992px) and (max-width: 1199px) 800px,
      (min-width: 1200px) and (max-width: 1919px) 1000px,
      (min-width: 1920px) 1200px
    `;
  };

  return (
    <p
      className={cx(
        'block image align',
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
        className,
      )}
      style={style}
    >
      {data.url && (
        <>
          {(() => {
            const imageUrl = flattenToAppURL(data.url);
            const srcSet = getSrcSet(imageUrl);
            const sizes = getSizes();

            const image = (
              <Image
                className={cx({
                  'full-width': data.align === 'full',
                  large: data.size === 'l',
                  medium: data.size === 'm',
                  small: data.size === 's',
                })}
                item={
                  data.image_scales
                    ? {
                      '@id': data.url,
                      image_field: data.image_field,
                      image_scales: data.image_scales,
                    }
                    : undefined
                }
                src={
                  data.image_scales
                    ? undefined
                    : isInternalURL(data.url)
                      ? (() => {
                        if (data.size === 'l')
                          return `${imageUrl}/@@images/image`;
                        if (data.size === 'm')
                          return `${imageUrl}/@@images/image/preview`;
                        if (data.size === 's')
                          return `${imageUrl}/@@images/image/mini`;
                        return `${imageUrl}/@@images/image`;
                      })()
                      : data.url
                }
                srcSet={srcSet}   // Correct usage of srcSet
                sizes={sizes}     // Pixel-based sizes for optimal image loading
                alt={data.alt || ''}
                loading='lazy'
                responsive={true}
              />
            );

            if (href) {
              return (
                <UniversalLink
                  href={href}
                  openLinkInNewTab={data.openLinkInNewTab}
                >
                  {image}
                </UniversalLink>
              );
            } else {
              return image;
            }
          })()}
        </>
      )}
    </p>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
