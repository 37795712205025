/**
 * Breadcrumbs components.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import social04 from '../Footer/ico-social04.svg';
import social08 from '../Footer/ico-social08.svg';
import social02 from '../Footer/ico-social02.svg';
import social01 from '../Footer/ico-social01.svg';
import social03 from '../Footer/ico-social03.svg';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

/**
 * Breadcrumbs container class.
 */
export class BreadcrumbsComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    root: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  componentDidMount() {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(nextProps.pathname));
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const title = this.props.root;
    return (
      <>
        <ul id='social-media'>
          <li><a href='https://www.youtube.com/user/YorkTelevision' rel='noopener' target='_blank'>
            <img src={social04} alt='youtube' width='30' height='30' /></a></li>
          <li><a href='https://www.instagram.com/york_college_cuny/' rel='noopener' target='_blank'>
            <img src={social08} alt='Instagram' width='30' height='30' /></a></li>
          <li><a href='https://www.linkedin.com/school/269983' rel='noopener' target='_blank'>
            <img src={social02} alt='LinkedIn' width='30' height='30' /></a></li>
          <li><a href='https://www.facebook.com/pages/York-College-CUNY/175813186163' target='_blank'>
            <img src={social01} alt='Facebook' width='30' height='30' /></a></li>
          <li><a href='https://twitter.com/YorkCollegeCUNY' rel='noopener' target='_blank'>
            <img src={social03} alt='twitter' width='30' height='30' /></a></li>
        </ul>
        <div className='cd-header-image'>

          <Link to={this.props.root || '/'}>

            <img
              alt={'Section ' + this.props.rootTitle + ' Header' || 'Section ' + this.props.title + ' Header' || 'Section Home'}
              src={this.props.root + '/header.jpg/@@images/image/preview' || '/header.jpg/@@images/image/preview'}
              srcSet={this.props.root + '/header.jpg/@@images/image/teaser 200w,' + this.props.root + '/header.jpg/@@images/image/large 400w,' + this.props.root + '/header.jpg/@@images/image/larger 600w,' + this.props.root + '/header.jpg/@@images/image/great 800w,' + this.props.root + '/header.jpg/@@images/image/huge 1000w' + this.props.root + '/header.jpg/@@images/image/hd 1200w' + this.props.root + '/header.jpg/@@images/image/2k 1920w' || ''}
            />

            {this.props.rootTitle !== this.props.siteTitle && this.props.rootTitle !== this.props.title && (
              <h2 className='sectiontitle'><span>{this.props.rootTitle}</span></h2>
            )}
          </Link>
        </div>
      </>
    )
      ;
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
      rootTitle: state.navroot?.data?.navroot?.title,
      siteTitle: state.site?.data?.['plone.site_title'],
      title: state.content?.data?.title,
    }),
    { getBreadcrumbs },
  ),
)(BreadcrumbsComponent);
